import React, {useState} from 'react';
import {Button, Input, Header } from 'semantic-ui-react';
import './login.css';
import '../utils.js';
import { isValidEmail } from '../utils.js';
import TermsConditions from './termsconditions.js';

export default function Login({switchToSignup, storeToken, setLimit, setType, setAccountId}){
    const [email, setEmail] = useState("");
    const [open, setOpen] = useState(false);
    const [code, setCode] = useState("");
    const [codeSent, setCodeSent] = useState(false);
    const [incorrect, setIncorrect] = useState(false);
    const [loading, setLoading] = useState(false);
    const [notfound, setNotfound] = useState(false);


    /**
     * Handles the input of the email 
     * @param {*} e 
     */
    const handleEmailChange = (e) => {
        var _function = 'handleEmailChange';
        //console.log(_function, 'called');    

        setEmail(e.target.value);
    }

    /**
     * Handles if the user pressed ENTER to actionnate login
     * @param {*} e 
     */
    const handleEmailKeyPress = (e) => {
        var _function = 'handleEmailKeyPress';
        //console.log(_function, ' called');

        if(e.key === 'Enter'){
            handleLogin();
        }
    }

    /**
     * Handles if the user pressed ENTER to actionate code verif
     * @param {*} e 
     */
    const handleCodeKeyPress = (e) => {
        var _function = 'handleCodeKeyPress';
        //console.log(_function, ' called');

        if(e.key === 'Enter'){
            handleVerifCode();
        }
    }

    
    /**
     * handles input of Code
     * @param {*} e 
     */
    const handleCodeChange = (e) => {
        var _function = 'handleCodeChange';
        console.log(_function, 'called');

        setCode(e.target.value);
    }

    /**
     * Handles when user click on login
     * @param {*} e 
     */
    const handleLogin = (e) =>{
        var _function = 'handleLogin';
        console.log(_function, 'called');

        if(email === '' || !isValidEmail(email)){
            // TO DO -- Add error message when the email is left empty
            console.log(_function, 'no email or invalid email');
            return ;
        }
        else{
            setLoading(true);

            console.log(_function, ' for email:', email);
            let body = {
                email:email,
            }
    
            fetch('/api/users/v1/signin', {
                method:'POST',
                headers: {
                    "Content-Type": "application/json",
                  },
                body:JSON.stringify(body)
            })
            .then((response) => {
                setLoading(false);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                console.log(_function, 'code has been sent', data.message);
                setCodeSent(true);
            })
            .catch(error =>{
                console.log(_function, 'error while trying to signing in email');
                setNotfound(true);
            })
        }

    }

    /**
     * Handles verification of the code when logging in
     * @param {*} e 
     */
    const handleVerifCode = (e) => {
        var _function = 'handleVerifCode';
        console.log(_function, 'called');

        if(code === ''){
            console.log(_function,' code is empty');
        }
        else{
            setLoading(true);
            let body = {
                code:code,
                email:email,
            }
    
            // TODO - Send code to backend to check if the code is the right one.
            fetch('/api/users/v1/validatecode', {
                method:'POST',
                headers: {
                    "Content-Type": "application/json",
                  },
                body:JSON.stringify(body)
            })
            .then((response) => {
                setLoading(false);

                if (!response.ok) {
                    setIncorrect(true);
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                console.log(_function, 'code has been validated');
                setLimit(data.account_limit);
                setType(data.account_type);
                storeToken(data.token, false);
                setAccountId(data.account_id);
            })
            .catch(error =>{
                console.log(_function, 'error while trying to signing up email');
            })   
        }

    }

    return <div className="loginMain">
        {
            codeSent ?
            <div className="loginCenter">
                <div className='loginheader'>
                    <Header as='h2' textAlign='center' style={{color:'#1f496f'}}>Enter the code sent to your email</Header>
                </div>
                <div className='loginInput'>
                    <Input placeholder="12345" value={code} onChange={handleCodeChange} onKeyPress={handleCodeKeyPress} />
                    {
                        incorrect &&
                            <div className='loginError'>
                                Code is incorrect or has exceeded its time limit.
                            </div>
                    }
                </div>
                <div className='loginButton'>
                    <Button primary loading={loading} onClick={handleVerifCode}>Verify code</Button>
                </div>
                {/* <div className='loginResend'>
                    If your code has expired, <span onClick={}>click here</span> to get a new code.
                </div> */}
            </div>
            :
            <div className="loginCenter">
                <div className="loginHeader">
                    <Header as='h2' style={{color:'#1f496f'}}>Enter your email</Header>
                </div>
                <div className='loginInput'>
                    <Input placeholder="john.doe@email.com" fluid focus value={email} onChange={handleEmailChange} onKeyPress={handleEmailKeyPress} />
                    {
                        notfound &&
                            <div className='loginError'>
                                Account was not found.
                            </div>
                    }
                </div>
                <div className="loginButton">
                    <Button primary loading={loading} onClick={handleLogin}>Login</Button>
                </div>
                <div className="loginNote">
                    If you don't have an account, <span onClick={switchToSignup} style={{ cursor: 'pointer', color: '#618FA8' }}>sign-up here</span>.
                </div>
                <div className="signupTC">
                    By signin-in, you agree to the <span onClick={(e) => setOpen(true)} style={{ cursor: 'pointer', color: '#618FA8' }}>Terms & Conditions</span> of this website.
                </div>
                <TermsConditions open={open} setOpen={setOpen} />
            </div>    
        }
    </div>
}