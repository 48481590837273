import React, { useEffect, useState, useRef } from 'react';
import { Button, Icon, Dropdown, DropdownItem, DropdownMenu, Popup } from 'semantic-ui-react';
import './userstory.css';


export default function UserStory({story, callbackUpdateStoryStatus, listProjects, setActiveItem}){
    const [jiraCompleted, setCompleted] = useState(true);
    const [hasCopied, setHasCopied] = useState(false);
    const contentRef = useRef();
;
    const handleSendtoJira = (story, _projectKey) => {
        var _function = 'handleSendtoJira';
        console.log(_function,'called');
    
        const body  = {
            id:story.id,
            projectKey:_projectKey,
        };
        setCompleted(false);

        let token = localStorage.getItem('token');
    
        fetch('/api/stories/v1/sendtojira', {
            method:'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
              },
            body:JSON.stringify(body)
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then((data) => {
            console.log(_function, 'successful JIRA story creation');
            setCompleted(true);
            if(data.status === 'ok'){
                callbackUpdateStoryStatus(story.id);
            }
        })
        .catch((error) => {
            console.error('There was a problem with sending JIRA story:', error);
        });    
    }

    /**
     * Copies the text of the PRD to the clipboard
     */
    const copytoClipboard = () =>{
        // navigator.clipboard.writeText("test");
        const contentHtml = contentRef.current.innerHTML;
        const blob = new Blob([contentHtml], { type: 'text/html' });
        const clipboardItem = new ClipboardItem({ 'text/html': blob });

        navigator.clipboard.write([clipboardItem])
        .then(()=>{
            setHasCopied(true);
            setTimeout(()=>{setHasCopied(false)}, 2000);
        })
        .catch((err) =>{
            console.error('Browser incompatible with copy to clipboard. ', err);
        })

    }


    const listCriterias = story.criterias ? story.criterias.map((o,i)=>{
        return <li key={"criteria_"+story.id+"_"+i.toString()}>{o}</li>;
    }) : [];


    const dropdownJira = listProjects.map(p => {
        return <DropdownItem value={p.id} onClick={(e) => handleSendtoJira(story, p.key)}>Send to <b>{p.name +' ('+p.key+')'}</b></DropdownItem>
    });

    return (
        <div>
        <div className="userstory_row">
            <div className="userstory_body" ref={contentRef}>
                <h3>{story.title}</h3>
                <h4>Description</h4>
                {story.description}
                <h4>Acceptance criteria</h4>
                <ul>
                    {listCriterias}
                </ul>
            </div>
            <div className="userstory_quickaction">
                <Popup trigger={
                    <div>
                        <Icon name={hasCopied ? 'clipboard check' :'clipboard outline'} onClick={copytoClipboard} link />
                    </div>
                } flowing hoverable position='top left'>
                    Copy to clipboard
                </Popup>
            </div>
            <div>
                {
                    !story.published ?
                        jiraCompleted ?
                        <Popup trigger={
                            <div>
                                <Dropdown disabled={dropdownJira.length === 0} className="userStoryDropdownImage"
                                    trigger={<img src="/images/mark-gradient-blue-jira.png" alt="Logo" style={{ height: '25px', width: '25px', objectFit: 'cover' }} />}
                                >
                                    <DropdownMenu>
                                        {dropdownJira}
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                            } flowing hoverable disabled={dropdownJira.length>0}>
                            Connect to <span onClick={()=>setActiveItem('integrations')} style={{ cursor: 'pointer', color: '#618FA8' }} >Jira</span>
                        </Popup>
                        :
                            <Button loading>Send to JIRA</Button>
                        :
                        <Icon name='check' size='small' />
                }
            </div>
        </div>
        <hr/>
        </div>
    );
}