import React, { useEffect, useState } from 'react';
import { Button, Icon, Dropdown, Input, Label } from 'semantic-ui-react';
import NotionHowTo from './notionhowto.js';
import { ACCOUNT_TYPES } from '../utils';
import './integrations.css';


export default function Integrations({setActiveItem, accountType}){
    const [isLoaded, setIsLoaded] = useState(false);
    const [open, setOpen] = useState(false);


    const [jiratoken, setJiraToken] = useState("");
    const [jiraemail, setJiraEmail] = useState("");
    const [jiradomain, setJiraDomain] = useState("");
    const [jiraemailmissing, setJiraEmailMissing] = useState(false);
    const [jiradomainmissing, setJiraDomainMissing] = useState(false);
    const [jiratokenmissing, setJiraTokenMissing] = useState(false);
    const [jiratokenplaceholder, setJiraTokenPlaceholder] = useState("API Token")

    const [notiontoken, setNotionToken] = useState("");
    const [notiontokenmissing, setNotionTokenMissing] = useState(false);
    const [notiontokenplaceholder, setNotionTokenPlaceholder] = useState("API Token")    

    const [testConnectionString, setTestConnectionString] = useState({state:'nok', text:''});
    const [testNotionString, setTestNotionString] = useState({state:'nok', text:''});

    useEffect(() => {
        var _function = 'Integrations useEffect';
        console.log(_function, 'Fetching data...');

        let token = localStorage.getItem('token');

        fetch('/api/integrations/v1/', {
            method:'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
                }
        })
        .then((response) => {
            if (!response.ok) {
                console.log(_function,'error when calling to retrieve integrations information');
                throw new Error('Network response was not ok');
            }
            else{
                console.log(_function, 'integrations found and processed');
                return response.json();
            }
        })
        .then(data =>{
            console.log(data);
            setJiraDomain(data.integrations.jira_domain);
            // setJiraToken(data.integrations.jira_token);
            if(data.integrations.jira_token !== ''){
                setJiraTokenPlaceholder(data.integrations.jira_token);
            }
            setJiraEmail(data.integrations.jira_email);

            // setNotionToken(data.integrations.notion_token);
            if(data.integrations.notion_token !== ''){
                setNotionTokenPlaceholder(data.integrations.notion_token);
            }
        })
        .catch(error => {
            console.log(_function,'error:',error);
        })
        
      }, []);

    /**
     * Method used to save integrations data.
     * @param {*} e 
     */
    const handleSaveJira = (e) => {
        var _function = 'handleSaveJira';
        console.log(_function, 'called');

        setJiraDomainMissing(false);
        setJiraEmailMissing(false);
        setJiraTokenMissing(false);

        if(jiratoken === ''){
            setJiraTokenMissing(true);
        }

        if(jiradomain === ''){
            setJiraDomainMissing(true);
        }

        if(jiraemail === ''){
            setJiraEmailMissing(true);
        }

        if(jiratoken === '' || jiraemail === '' || jiradomain === ''){
            console.log(_function, 'one of the information is missing');
        }
        else{
            console.log(_function, 'all good nothing is missing');
            let body = {
                jira_token:jiratoken,
                jira_domain:jiradomain,
                jira_email:jiraemail,
            }

            let token = localStorage.getItem('token');
    
            fetch('/api/integrations/v1/jira', {
                method:'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                  },
                body:JSON.stringify(body)
            })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                console.log(_function, 'successful Jira integration setup');
    
                fetch('/api/integrations/v1/confluencespaces', {
                    method:'GET',
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                      },
                })
                .then((response) => {
                    if (!response.ok) {
                        setTestConnectionString({state:'nok', text:"Error connecting. Please check connection details."});
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then((result) =>{
                    // Connection worked we can now obfuscate the actual token.
                    let obfuscated = '***************'+jiratoken.slice(-5);
                    setJiraToken('');
                    setJiraTokenPlaceholder(obfuscated);
                    setTestConnectionString({state:'ok', text:"Connection successful."});
                })
                .catch((error) => {
                    setTestConnectionString({state:'nok', text:"Error connecting. Please check connection details."});
                });
    
            })
            .catch((error) => {
                console.error('There was a problem with saving Jira integration:', error);
            });   
        }


    }

    /**
     * Handles saving Notion integration parameters
     * @param {*} e 
     */
    const handleSaveNotion = (e) => {
        var _function = 'handleSaveNotion';
        console.log(_function, 'called');

        setNotionTokenMissing(false);

        if(notiontoken === ''){
            setNotionTokenMissing(true);
        }

        if(notiontoken === ''){
            console.log(_function, 'one of the information is missing');
        }
        else{
            console.log(_function, 'all good nothing is missing');

            let body = {
                notion_token:notiontoken,
            }

            let token = localStorage.getItem('token');
    
            fetch('/api/integrations/v1/notion', {
                method:'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                  },
                body:JSON.stringify(body)
            })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                fetch('/api/integrations/v1/notionpages', {
                    method:'GET',
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                      },
                })
                .then((response) => {
                    if (!response.ok) {
                        setTestConnectionString({state:'nok', text:"Error connecting. Please check connection details."});
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then((result) =>{
                    // Connection worked we can now obfuscate the actual token.
                    let obfuscated = '***************'+notiontoken.slice(-5);
                    setNotionToken('');
                    setNotionTokenPlaceholder(obfuscated);
                    setTestNotionString({state:'ok', text:"Connection successful."});
                })
                .catch((error) => {
                    setTestNotionString({state:'nok', text:"Error connecting. Please check connection details."});
                });                
            });
        }
    }

    /**
     * Handles the textbox for Jira Domain
     * @param {*} e 
     */
    const handleJiraDomain = (e) => {
        var _function = 'handleJiraDomain';
        console.log(_function, 'called');

        setJiraDomain(e.target.value);
    }

    /**
     * Handles the textbox for Jira Token
     * @param {*} e 
     */
    const handleJiraToken = (e) => {
        var _function = 'handleJiraToken';
        console.log(_function, 'called');

        setJiraToken(e.target.value);
    }

    /**
     * Handles the textbox for Jira Email
     * @param {*} e 
     */
    const handleJiraEmail = (e) => {
        var _function = 'handleJiraEmail';
        console.log(_function, 'called');

        setJiraEmail(e.target.value);
    }

    /**
     * Handles the textbox for Notion Token
     * @param {*} e 
     */
    const handleNotionToken = (e) => {
        var _function = 'handleNotionToken';
        console.log(_function, 'called');

        setNotionToken(e.target.value);
    }

    return <div className="integrationCenter">
        <span onClick={()=>setActiveItem('initiatives')} style={{ cursor: 'pointer', color: '#618FA8' }}>Back home</span>
        {
            accountType === ACCOUNT_TYPES.free ? 
            <div className="integrationNeedSubscription">
                <p>Jira, Confluence & Notion integrations are available for <b>Premium accounts</b> and above.<br/></p>
                <Button primary onClick={()=> setActiveItem('account')}>Upgrade subscription</Button>
            </div>
            :
            <div className="integrationSection">
                <div className='integrationHeader'>
                    <h2>Jira & Confluence Integration</h2>
                </div>
                <div className="integrationSubheader">Connect JIRA and/or Confluence to automatically push user stories to your backlog or PRDs to your document system.</div>
                <div className="integrationJira">
                    <table>
                        <tr>
                            <td className='integrationColHeader'>
                                Atlassian account email
                            </td>
                            <td>
                                <Input placeholder='my@email.com' onChange={handleJiraEmail} value={jiraemail} />{jiraemailmissing && <Label basic color='red' pointing='left'>Needs a value</Label>}
                            </td>
                        </tr>
                        <tr>
                            <td className='integrationColHeader'>
                                Atlassian API token
                            </td>
                            <td>
                                <Input placeholder={jiratokenplaceholder} onChange={handleJiraToken} value={jiratoken} />{jiratokenmissing && <Label basic color='red' pointing='left'>Needs a value</Label>}&nbsp;<a href='https://id.atlassian.com/manage-profile/security/api-tokens' target='_blank'  rel='noreferrer'>Link to API token page</a>
                            </td>
                        </tr>
                        <tr>
                            <td className='integrationColHeader'>
                                Atlassian domain
                            </td>
                            <td>
                                <Input placeholder='mydomain.atlassian.net' onChange={handleJiraDomain} value={jiradomain} /> {jiradomainmissing && <Label basic color='red' pointing='left'>Needs a value</Label>}
                            </td>
                        </tr>
                    </table>
                    
                    <div className='integrationJiraButton'>
                        <Button primary onClick={handleSaveJira}>Save & test connection</Button>
                        {testConnectionString.text !== '' && <Label color={testConnectionString.state === 'ok' ? 'green': 'red'}>{testConnectionString.text}</Label>}
                    </div>
                </div>

                <hr/>

                
                <h2>Notion Integration</h2>
                <div className="integrationSubheader">Connect Notion to automatically push PRDs to your document system. Follow the <span onClick={(e) => setOpen(true)} style={{ cursor: 'pointer', color: '#618FA8' }}>instructions here.</span></div>
                <div className="integrationNotion">
                    <table>
                        <tr>
                            <td className='integrationColHeader'>
                                Notion token
                            </td>
                            <td>
                                <Input placeholder={notiontokenplaceholder} onChange={handleNotionToken} value={notiontoken}/>{notiontokenmissing && <Label basic color='red' pointing='left'>Needs a value</Label>}&nbsp;<a href='https://www.notion.so/my-integrations' target='_blank' rel='noreferrer'>Link to integrations page</a>
                            </td>
                        </tr>
                    </table>
                </div>
                <div>
                    <Button primary onClick={handleSaveNotion}>Save & test connection</Button>
                    {testNotionString.text !== '' && <Label color={testNotionString.state === 'ok' ? 'green': 'red'}>{testNotionString.text}</Label>}
                </div>
                <NotionHowTo open={open} setOpen={setOpen} />
            </div>
        }
    </div>
};
