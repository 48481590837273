import React, { useEffect, useRef, useState } from 'react';
import { Form, TextArea, TabPane, Tab, Header, Checkbox, Dropdown, DropdownMenu, DropdownItem, DropdownDivider, DropdownHeader, Icon, List, ListItem, ListHeader, Input, Progress, Popup} from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import 'react-tabs/style/react-tabs.css';
import './App.css';
import * as Constants from './test.js';
import { getTodayDate, getTodayYearMonth, ACCOUNT_TYPES, REVERSE_ACCOUNT_TYPES } from './utils';
import Integrations from './components/integrations.js';
import Accounts from './components/accounts.js';
import Login from './components/login.js';
import Signup from './components/signup.js';
import InitialSetup from './components/initialsetup.js';
import TabPrd from './components/tabprd.js';
import Context from './components/context.js';
import TabStories from './components/tabstories.js';
import Feedback from './components/feedback.js';

import { Boarding } from "boarding.js";

import Clarity from '@microsoft/clarity';

import "boarding.js/styles/main.css";
import "boarding.js/styles/themes/basic.css";

function App() {
    const [prd, setPrd] = useState({});
    const [userStories, setUserStories] = useState([]);

    const [documentation, setDocumentation] = useState('No documentation yet.');
    
    // TO DO - Remove that constant that's here only for testing purposes on dev.
    const [initiative, setInitiative] = useState("");
    const [countInitiativeChars, setCountInitiativeChars] = useState(0);
    const [initiative_id,setInitiativeId]=useState();
    const [activeItem, setActiveItem]= useState('initiatives');
    const [listInitiatives, setListInitiatives] = useState([]);

    const [usageCount, setUsageCount] = useState(0);
    const [usageLimit, setUsageLimit] = useState(0);
    const [accountType, setAccountType] = useState(0);
    const [accountId, setAccountId] = useState(0);
    
    const [loggedIn, setLoggedin] = useState(false);
    const [historyChange, setHistoryChange] = useState(false);
    const [signup, setSignup] = useState(false);
    const [showInit, setShowInit] = useState(false);
    const isInitialMount = useRef(true);

    const [promptHasChanged, setPromptHasChanged] = useState(false);

    const [initiativeSearch, setInitiativeSearch] = useState("");
    const [checked, setChecked] = React.useState(false)

    const [open, setOpen] = useState(false);

    const MAX_INITIATIVE_CHARS = 300;

    const projectId = process.env.REACT_APP_CLARIFY;
    Clarity.init(projectId);

    const boarding = new Boarding({
        opacity:0.35,
        animate:true,
    });

    // Define the steps for introduction
    boarding.defineSteps([
      {
        element: "#intro-describe-initiative",
        popover: {
          title: "Step 1 - Explain the initiative",
          description: "Provide a short description of your initiative",
          prefferedSide: "top",
          alignment:"start"
        },
      },
      {
        element: "#intro-click-generate",
        popover: {
            title: "Step 2 - Generate PRD",
            description: "Click Generate to get your prefilled PRD.",
            prefferedSide: "top",
            alignment:"start"
        },
      },
      {
        element: "#intro-click-publish",
        popover: {
            title: "Step 3 - Publish",
            description: "Publish the PRD to your system of choice for futher edition.",
            prefferedSide: "top",
            alignment:"start"
        },
      },
      {
        element: "#intro-click-stories",
        popover: {
            title: "Step 4 - Switch to <u>User Stories</u>",
            description: "Switch to the User stories section.",
            prefferedSide: "top",
            alignment:"start"
        },
      },
      {
        element: "#intro-publish-stories",
        popover: {
            title: "Step 5 - Generate & Publish",
            description: "Click <u>Generate Stories</u> and send stories to Jira, in bulk or individually, for final edition.",
            prefferedSide: "top",
            alignment:"start"
        },
      },
    ]);


    if (process.env.REACT_APP_ENV !== 'DEV') {
        console.log = function() {};
    }

    useEffect(() =>{
        var _function = 'App useEffect';
        console.log(_function, 'called');

        let token = localStorage.getItem('token');


        // Make sure the token exists and that it's the first time.
        if(isInitialMount.current && !loggedIn && token){
            console.log(_function, 'Going through initial Mount');
            isInitialMount.current = false;

            console.log(_function, 'NOT logged in, checking token');
            fetch('/api/users/v1/verifytoken', {
                method:'GET',
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`
                    },
            })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('token is not valid anymore');
                }

                console.log(_function,'token is valid, skip login screen');
                return response.json();
            })
            .then((data) => {
                setAccountType(data.account_type);
                setUsageLimit(data.account_limit);
                setLoggedin(true);
                if(signup){
                    boarding.start();
                }
            })
            .catch(error =>{
                console.log(_function,'token not valid anymore');
                setLoggedin(false);
            })
        }
        // token exists and we're logged in, now we can fetch the data we need.
        else if((loggedIn && token) || historyChange){
            console.log(_function, 'loading the initiatives history');
            fetch('/api/initiatives/v1/getallinitiatives', {
                method:'GET',
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`
                    },
            })
            .then(response =>{
                if(!response.ok){
                    console.log(_function, 'error retrieving the history of initiatives');
                }
                else{
                    console.log(_function, 'successful retrieval of history');
                    return response.json();
                }

            })
            .then(data =>{
                console.log(_function,'successfully retrieved all initiatives, now parsing');
                console.log(data);
                let today = getTodayYearMonth();
                let countInitiatives = data.initiatives.filter(element => element.created_at.slice(0,7) === today).length;
                setListInitiatives(data.initiatives);
                setUsageCount(countInitiatives);
                setHistoryChange(false);
            })
            .catch(error =>{
                console.log(_function, 'error loading all initiatives', error);
            })
        }


    }, [loggedIn, historyChange]);



    /**
     * This function is passed as a callback to the Signup component to handle if the user clicked on "I have a Login"
     * @param {*} e 
     */
    const switchToLogin = (e) =>{
        var _function = 'switchToLogin';
        console.log(_function, 'called');

        setSignup(false);
    }

    /**
     * This function is passed as a callback to Login compoment to handle if the user clicked on "create account"
     * @param {*} e 
     */
    const switchToSignup = (e) =>{
        var _function = 'switchToSignup';
        console.log(_function, 'called');

        setSignup(true);
    }

    /**
     * This function is passed as a callback to InitialSetup in case user wants to skip the step
     * @param {*} e 
     */
    const skipInitialSetup = (e) => {
        var _function = 'skipInitialSetup';
        console.log(_function, _function);

        setShowInit(false);
    }

    /**
     * Handles the storage of the token into LocalStorage
     * @param {*} newToken 
     * @param {*} fromsignup 
     */
    const storeToken = (newToken, fromsignup) => {
        var _function = 'storeToken';
        console.log(_function, 'called');

        localStorage.setItem('token', newToken);

        Clarity.identify(accountId);

        // setShowInit(fromsignup);
        setLoggedin(true);
    }

    
    const checkCanGenerate = () =>{
        return usageCount<usageLimit;
    }

    const handleSearch = (e) =>{
        console.log('handleSearch');
        setInitiativeSearch(e.target.value);
    }

    /**
     * Method used to update the State of text when text is typed in the textbox.
     * @param {*} e 
     */
    const handleInitiativeChange = (e) => {
        console.log('handleInitiativeChange');

        if(e.target.value.length <= MAX_INITIATIVE_CHARS){
            setInitiative(e.target.value);
            setCountInitiativeChars(e.target.value.length);
            if(!promptHasChanged){
                setPromptHasChanged(true);
            }
        }
    }

    const handleClearTextArea = () =>{
        console.log('handleClearTextArea');
        setInitiative("");
        setCountInitiativeChars(0);
        setPrd({});
        setUserStories([]);
    }

    /**
     * Handles the menu top right
     * @param {*} e 
     */
    const handleDropdownMenu = (event, data) => {
        var _function = 'handleDropdownMenu';
        console.log(_function, 'called');
        console.log(data);

        if(data.value === 'signout'){
            console.log(_function, 'signing out');

            localStorage.removeItem('token');

            isInitialMount.current = true;
            setPromptHasChanged(false);
            setInitiative("");
            setInitiativeId(null);
            setCountInitiativeChars(0);
            setUsageCount(0);
            setUsageLimit(0);
            setAccountType(0);
            setPrd({});
            setUserStories([]);
            setListInitiatives([]);
            setHistoryChange(false);
            setSignup(false);
            setLoggedin(false);

        }
        else{
            setActiveItem(data.value);
        }
    }


    /**
     * Handles when user clicks on one entry of the History List
     * @param {*} e 
     * @param {*} prdId 
     */
    const handleHistoryListClick = (e, prdId) => {
        var _function = 'handleHistoryListClick';
        console.log(_function, 'called');

        let token = localStorage.getItem('token');

        if(prdId && token){
            console.log(_function, 'trying to retrieve PRD and initiative');
            fetch('/api/prds/v1/'+prdId, {
                method:'GET',
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`
                    },
            })
            .then(response =>{
                if(!response.ok){
                    throw new Error("Error while retrieving the PRD");
                }

                console.log(_function, 'successful retrieval of the PRD');
                return response.json();
            })
            .then(data =>{
                console.log(_function, 'now updating the current UX');
                console.log(data.prd);

                setInitiative(data.prd.description);
                setInitiativeId(data.prd.initiative_id);
                setCountInitiativeChars(data.prd.description.length);
                setPrd(data.prd.json);
                setUserStories(data.prd.stories);
                setPromptHasChanged(false);
            })
            .catch(error =>{
                console.log(_function, 'error loading the selected PRD', error);
            })
        }
    }
 
    // Creates the tabs content.
    const panes = [
        { menuItem: 'PRD', render: () => <TabPane className="panel" style={{color:'#1f496f'}}>
                <TabPrd initiative={initiative} setInitiativeId={setInitiativeId} prd={prd} setPrd={setPrd} setActiveItem={setActiveItem} setHistoryChange={setHistoryChange} setPromptHasChanged={setPromptHasChanged} promptHasChanged={promptHasChanged} checkCanGenerate={checkCanGenerate} includeContext={checked}/>
            </TabPane> },
        { menuItem: 'User stories', render: () => <TabPane className="panel" style={{color:'#1f496f'}}>
                <TabStories initiative_id={initiative_id} prd={prd} userStories={userStories} setUserStories={setUserStories} setActiveItem={setActiveItem} promptHasChanged={promptHasChanged}/>
            </TabPane> },
      ];

    let startHistoryDate = '';
    console.log('Appjs list of initiatives before render: ',listInitiatives);
    const historyList = listInitiatives.map(i => {
        // No need to transform to Dates as the query already sorted the created_at in descending order
        const cutoff = 30;

        if(i.title.toLowerCase().includes(initiativeSearch.toLowerCase())){
                let item = startHistoryDate !== i.created_at ? 
                <><ListItem key={"li_header_"+i.id}><ListHeader key={"li_header_item"+i.id} style={{color:'#1f496f'}}>{i.created_at}</ListHeader></ListItem><ListItem key={"li_item"+i.id} onClick={(e)=>handleHistoryListClick(e,i.prd_id)}>{i.title.length >= cutoff ? i.title.slice(0,cutoff)+'...' : i.title}</ListItem></>
                :
                <ListItem key={"li_item"+i.id} onClick={(e)=>handleHistoryListClick(e,i.prd_id)}>{i.title.length >= cutoff ? i.title.slice(0,cutoff)+'...' : i.title}</ListItem>;
            startHistoryDate = i.created_at;
            return item;
        }
    });


    return (
        <div className="App">
            <header className="App-header">
                <div className="header">
                    <div className="headerTitle"><span onClick={()=>setActiveItem('initiatives')} style={{ cursor: 'pointer'}} ><img src='/images/productly_logo.png' style={{width:'160px'}}></img></span></div>
                    {
                        loggedIn && <div className="headerMenusRight">
                            <div className="headerFeedback">
                                <Icon name='mail outline' onClick={(e)=>setOpen(true)}/>
                            </div>
                            <div className="headerUserMenu">
                                <div className="headerUserAccountLogin" id="intro-user-dropdown">
                                    <Dropdown
                                        icon={<Icon name='user circle outline' size='big'/>}
                                        direction='left'
                                    >
                                        <DropdownMenu>
                                            <DropdownHeader>{REVERSE_ACCOUNT_TYPES[accountType]} account</DropdownHeader>
                                            <DropdownItem key='dd_account' value='account' onClick={handleDropdownMenu} style={{color:'#1f496f'}}>Account</DropdownItem>
                                            <DropdownItem key='dd_integrations' value='integrations' onClick={handleDropdownMenu} style={{color:'#1f496f'}}>Integrations</DropdownItem>
                                            <DropdownItem key='dd_context' value='context' onClick={handleDropdownMenu} style={{color:'#1f496f'}}>Context</DropdownItem>
                                            <DropdownDivider/>
                                            <DropdownItem key='dd_signout' value='signout' onClick={handleDropdownMenu} style={{color:'#1f496f'}}>Sign out</DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </header>
            {
                !loggedIn && signup ?
                    <Signup switchToLogin={switchToLogin} storeToken={storeToken} setLimit={setUsageLimit} setType={setAccountType} setAccountId={setAccountId} /> 
                :
                    !loggedIn && !signup ?
                        <Login switchToSignup={switchToSignup} storeToken={storeToken}  setLimit={setUsageLimit} setType={setAccountType} setAccountId={setAccountId} />
                    :
                        loggedIn && showInit ?
                            <InitialSetup skipInitScreen={skipInitialSetup}/> 
                        : 
                            <div className="content">
                                <div className="history">
                                    { accountType === ACCOUNT_TYPES.free &&
                                        <div className="headerUserConsumption">
                                            <div>
                                                Free PRDs used ( {usageCount} out of {usageLimit})
                                                <Progress total={usageLimit} value={usageCount} size='tiny' color='green'  />
                                            </div>
                                            <hr/>
                                        </div>
                                    }
                                    <div className="historyHeader">
                                        <Header as="h3" style={{color:'#1f496f'}}>Initiatives history</Header>
                                    </div>
                                    {
                                        historyList.length !== 0 ? 
                                        <>
                                            <div className="historySearch">
                                                <Input fluid placeholder="Type to search an initiative" value={initiativeSearch} onChange={handleSearch} />
                                            </div>
                                            <div className="historyList" key="historyList">
                                                <List relaxed selection>
                                                    {historyList}
                                                </List>
                                            </div>
                                        </>
                                        :
                                            <div className="historyListEmpty">
                                                <i>Create your first initiative</i>
                                            </div>
                                    }

                                </div>

                                <div className="main">
                                    {
                                        activeItem === 'initiatives'?
                                        <div>
                                            <div className="inputsection" id="intro-describe-initiative">
                                                <Form className="inputInitiativeText">
                                                    <Header as="h3" style={{color:'#1f496f'}}>1. Describe your initiative</Header>
                                                    <div className="inputInitiativeTextInstruction">
                                                        Describe your initiative, its purpose, its intended users and the features in as many details as possible.
                                                    </div>
                                                    <div className="inputSectionTextArea">
                                                        <TextArea
                                                            placeholder='E.g. An interface that let product managers briefly describe an initiative and, using generative AI, it automatically create a PRD and the subsequent user stories. This enables PMs to save tremendous amount of time and let them focus on more strategic work.' 
                                                            value={initiative}
                                                            onChange={handleInitiativeChange}
                                                            style={{borderRadius:'10px', resize:'none'}}
                                                            />
                                                        <Popup trigger={
                                                            <Icon name='delete' className="inputSectionTextAreaClear" onClick={handleClearTextArea}/>
                                                        } flowing hoverable position='top left'>
                                                        Clear Initiatve, PRD and Stories
                                                    </Popup>
                                                    </div>
                                                    <div className="inputSectionSubText">
                                                        <div className="inputSectionSubTextCheckbox">
                                                            <Checkbox label={<label>Include <span onClick={()=>setActiveItem('context')} style={{ cursor: 'pointer', color: '#618FA8' }}>business context</span></label>}
                                                                onChange={(e, data) => setChecked(data.checked)}
                                                                checked={checked}
                                                            />
                                                        </div>
                                                        <div className='initCounter'>
                                                            {countInitiativeChars}/{MAX_INITIATIVE_CHARS}
                                                        </div>
                                                    </div>
                                                </Form>
                                            </div>

                                            <Header as="h3" style={{color:'#1f496f'}}>2. Review and refine</Header>

                                            <div id="intro-click-stories" className="results">
                                                <Tab panes={panes} style={{color:'#1f496f'}}/>
                                            </div>
                                        </div>
                                        : activeItem === 'integrations'?
                                            <Integrations setActiveItem={setActiveItem} accountType={accountType}/>
                                        : activeItem === 'context'?
                                        <div>
                                            <Context setActiveItem={setActiveItem} />
                                        </div>
                                        : activeItem === 'account'?
                                            <Accounts setActiveItem={setActiveItem} accountType={accountType} setType={setAccountType} />
                                        :
                                        <div>
                                        </div>
                                    }
                                </div>
                                <Feedback  open={open} setOpen={setOpen} />
                            </div>
            }
        </div>
    );
}

export default App;
