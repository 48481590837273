import React, {useState} from 'react';
import {Button, Input, Header } from 'semantic-ui-react';
import './initialsetup.css';

export default function InitialSetup({skipInitScreen}){
    const [personas, setPersonas] = useState("");
    const [product, setProduct] = useState("");
    const [business, setBusiness] = useState("");
    const [countPersonas, setCountPersonas] = useState(0);
    const [countBusiness, setCountBusiness] = useState(0);
    const [countProduct, setCountProduct] = useState(0);

    const MAX_CHARS = 150;


    const handlePersonaChange = (e) => {
        var _function = 'handlePersonaChange';
        console.log(_function, 'called');

        if(e.target.value.length <= MAX_CHARS){
            setPersonas(e.target.value);
            setCountPersonas(e.target.value.length);
        }

    }

    const handleProductChange = (e) => {
        var _function = 'handleProductChange';
        console.log(_function, 'called');

        if(e.target.value.length <= MAX_CHARS){
            setProduct(e.target.value);
            setCountProduct(e.target.value.length);
        }
    }

    const handleBusinessChange = (e) => {
        var _function = 'handleBusinessChange';
        console.log(_function, 'called');

        if(e.target.value.length <= MAX_CHARS){
            setBusiness(e.target.value);
            setCountBusiness(e.target.value.length);
        }
    }
    
    /**
     * Handles when user click on login
     * @param {*} e 
     */
    const handleSave = (e) =>{
        var _function = 'handleSave';
        console.log(_function, 'called');

        let body = {
            product:product,
            business:business,
            personas:personas,
        }

        let token = localStorage.getItem('token');

        fetch('/api/users/v1/newContext', {
            method:'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
              },
            body:JSON.stringify(body)
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            console.log(_function, 'context successfully saved');
            skipInitScreen();
        })
        .catch(error =>{
            console.log(_function, 'error while trying to save context');
        })
    }



    return <div className="initMain">
            <div className="initCenter">
                <div className="initInputSection">
                    <div className="initHeader">
                        <Header as='h2' style={{color:'#1f496f'}}>Business Context</Header>
                    </div>
                    <div className="initNote">
                        To increase the accuracy of the generated content you can provide additional context on your business.
                    </div>

                    <div className="initSubHeader">
                        <Header as='h3' style={{color:'#1f496f'}}>Business</Header>
                    </div>                
                    <div className="initInstructions">
                        Describe the value proposition of your business and its economic drivers
                    </div>
                    <div className='initInput'>
                        <Input fluid placeholder="Describe the value proposition of your business and its economic drivers" value={business} onChange={handleBusinessChange} />
                        <div className='initCounter'>
                            {countBusiness}/{MAX_CHARS}
                        </div>
                    </div>

                    <div className="initSubHeader">
                        <Header as='h3' style={{color:'#1f496f'}}>Personas</Header>
                    </div>                
                    <div className="initInstructions">
                        Describe the users of your products, their roles and their objectives
                    </div>
                    <div className='initInput'>
                        <Input fluid placeholder="Describe the users of your products, their roles and their objectives" value={personas} onChange={handlePersonaChange} />
                        <div className='initCounter'>
                            {countPersonas}/{MAX_CHARS}
                        </div>                        
                    </div>

                    <div className="initSubHeader">
                        <Header as='h3' style={{color:'#1f496f'}}>Product</Header>
                    </div>                
                    <div className="initInstructions">
                        Describe your product and its key capabilities
                    </div>
                    <div className='initInput'>
                        <Input fluid placeholder="Describe your product and its key capabilities" value={product} onChange={handleProductChange} />
                        <div className='initCounter'>
                            {countProduct}/{MAX_CHARS}
                        </div>                        
                    </div>
                </div>

                <div className="initButton">
                    <Button secondary onClick={handleSave}>Skip</Button>
                    <Button primary onClick={handleSave}>Save</Button>
                </div>
            </div>
    </div>
}