/**
 * Validates that the passed string is a correct email format
 * @param {*} email 
 * @returns true or false
 */
export function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

/**
 * 
 * @returns today's date in the format YYYY-MM-DD
 */
export function getTodayDate(){
    const today = new Date();
    // Format '2024-06-30'
    const dateString = today.toISOString().split('T')[0];
    
    return dateString;
}

export function getTodayYearMonth(){
    return getTodayDate().slice(0,7);
}

export function isAfterToday(dateString){
    const today = new Date();
    const current = new Date(dateString);

    return today < current;
}

export const ACCOUNT_TYPES = {
    free:1,
    paid:2,
    enterprise:3,
}

export const REVERSE_ACCOUNT_TYPES = {
  1:'Starter',
  2:'Premium',
  3:'Enterprise',
}
