import React, { useEffect, useState } from 'react';
import { Button, Icon, Modal, ModalHeader, ModalActions, ModalContent,TextArea, ModalDescription, Form, Placeholder, PlaceholderLine } from 'semantic-ui-react';
import './highlightable.css';


export default function Highlightable({text, category, prdId, setPrd}){
    const [isHovered, setIsHovered] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [details, setDetails] = useState("");

    const MAX_DETAILS_CHARS = 200;

    const expandCategory = (e) => {
        var _function = 'Highlightable expandCategory';
        console.log(_function, 'Sending expand request...');

        let body = {
            prd_id:prdId,
            category:category,
            details:details,
        }

        let token = localStorage.getItem('token');

        setIsLoading(true);


        fetch('api/initiatives/v1/expandPRD', {
            method:'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
              },
            body:JSON.stringify(body)
        })
        .then(result => {
            if(parseInt(result.status) === 200){
                console.log(_function, 'successfull call to get Expansion');
                return result.json();
            }
            else{
                console.log(result);
                return {prd:"Error processing PRD"};
            }
        })        
        .then((response) => {
            console.log(_function, 'successfully processed the PRD response and saving it in the state');
            console.log(response);

            setIsLoading(false);
            setPrd(response.prd);
        })
        .catch((error) => {
            console.log(_function, 'error while generating PRD');
        });


        setIsOpen(false);
        setIsHovered(false);
    }


    const handleDetailsChange = (e) =>{
        console.log('handleDetailsChange');

        if(e.target.value.length <= MAX_DETAILS_CHARS){
            setDetails(e.target.value);
        }
    }

    return <div>
        {
                isLoading ?
                    <Placeholder fluid>
                        <PlaceholderLine />
                        <PlaceholderLine />
                        <PlaceholderLine />
                    </Placeholder>
                :
                    <div className="highlightContent"
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    >
                        <div className="highlightText">
                            {text}
                        </div>
                        {
                            isHovered && <Button className="highlightButton" circular primary onClick={() => setIsOpen(true)}>Expand ✨</Button>
                        }
                        <Modal size="small" open={isOpen}>
                            <ModalHeader>Provide the AI with guidance to expand on the {category}</ModalHeader>
                            <ModalContent>
                                <ModalDescription>
                                    <Form>
                                        <TextArea 
                                            placeholder={`Inputs and guidance for the AI to expand on the ${category}.`}
                                            value={details}
                                            onChange={handleDetailsChange}
                                            style={{borderRadius:'10px', resize:'none'}}
                                        />
                                        <div className='highlightableInitCounter'>
                                            {details.length}/{MAX_DETAILS_CHARS}
                                        </div>
                                    </Form>
                                </ModalDescription>
                            </ModalContent>
                            <ModalActions>
                            <Button onClick={() => {setIsOpen(false); setIsHovered(false);}}>
                                Cancel
                            </Button>
                            <Button primary disabled={details.length === 0} onClick={expandCategory}>
                                Expand
                            </Button>
                            </ModalActions>
                        </Modal>
            </div>
            }
    </div>

}