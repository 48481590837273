import React, {useState} from 'react';
import { Modal, ModalHeader, ModalContent, ModalActions, Button, Rating, Table, TableBody, TableRow, TableCell, TableHeaderCell, TableHeader, TextArea, Form} from 'semantic-ui-react';
import './feedback.css';

export default function Feedback({open, setOpen}){
    const [morefeedback, setMorefeedback] = useState("");
    const [ratings, setRatings] = useState({})

    const sendFeedback = () =>{
        let body = {
            ratings:ratings,
            feedback:morefeedback,
        }

        let token = localStorage.getItem('token');

        fetch('/api/users/v1/sendfeedback', {
            method:'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
              },
            body:JSON.stringify(body)
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then((data) => {
            setOpen(false);
        })
        .catch((err) => {
            setOpen(false);
            console.error('There was a problem with sending feedback:', err);
        })

    }

    const updateRating = (e,data,cat) =>{
        console.log(data);
        let old = {...ratings};
        old[cat] = data.rating;
        setRatings(old);
    }

    const updateMore = (e) =>{
        setMorefeedback(e.target.value);
    }

    return <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
    >
        <ModalHeader>We value your feedback</ModalHeader>
        <ModalContent>

            <div>
                Please rate your experience on a scale from 1 (not at all) to 5 (totally)
            </div>
            <Table celled padded striped compact>
                <TableBody>
                    <TableRow>
                        <TableHeaderCell width={5}>
                            Provides value
                        </TableHeaderCell>
                        <TableCell>
                            <Rating maxRating={5} clearable onRate={(e,data) => updateRating(e,data, "value")}/>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableHeaderCell>
                            Easy to use
                        </TableHeaderCell>
                        <TableCell>
                            <Rating maxRating={5} clearable onRate={(e,data) => updateRating(e,data, "ease")}/>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableHeaderCell>
                            Worth paying for it
                        </TableHeaderCell>
                        <TableCell>
                            <Rating maxRating={5} clearable onRate={(e,data) => updateRating(e,data, "worth")}/>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableHeaderCell>
                            Premium price is fair
                        </TableHeaderCell>
                        <TableCell>
                            <Rating maxRating={5} clearable onRate={(e,data) => updateRating(e,data, "fair")}/>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <br/>
            Anything else on your mind ?
            <Form>
                <TextArea placeholder="Thanks for taking the time to provide more details..." 
                    value={morefeedback}
                    onChange={updateMore}
                    style={{borderRadius:'10px', resize:'none'}}
                />
            </Form>
        </ModalContent>
        <ModalActions>
            <Button onClick={ ()=>setOpen(false)}>Cancel</Button>
            <Button onClick={sendFeedback} primary>Send</Button>
        </ModalActions>
    </Modal>
}