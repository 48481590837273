import React, { useEffect, useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import Highlightable from './highlightable';
import './prd.css';


export default function Prd({prd, setPrd}){

    const listFeatures = !prd.hasOwnProperty("features") ? [] : prd.features.map((f,i) =>{
           return <div className="prdFeatures" key={"feature_"+i.toString()}>
                <h3>
                    {f.name}
                </h3>   
                {f.description}
            </div>;
    });

    const listMetrics = !prd.hasOwnProperty("success_metrics") ? [] : prd.success_metrics.map((s,i) => {
            return <div className="prdMetrics" key={"metrics_"+i.toString()}>
                <h3>
                    {s.name}
                </h3>
                {s.description}
            </div>;
    });
    
    const listNegativeMetrics = !prd.hasOwnProperty("negative_metrics") ? [] : prd.negative_metrics.map((s,i) => {
        return <div className="prdMetrics" key={"negmetrics_"+i.toString()}>
            <h3>
                {s.name}
            </h3>
            {s.description}
        </div>;
    });

    const listUserFlow = !prd.high_level_solution.hasOwnProperty("user_flow") ? [] : prd.high_level_solution.user_flow.map((s,i) =>{
        return <li key={"user_flow_li"+i.toString()}>{s}</li>;
    });

    const data_considerations = !prd.high_level_solution.hasOwnProperty("data_considerations") ? [] : prd.high_level_solution.data_considerations.map((s,i) =>{
        return <li key={"data_considerations_li"+i.toString()}>{s}</li>;
    });

    return <div className="prdContent">
        {
            !prd.hasOwnProperty('title') ?
                <div className="prdEmpty">No PRD yet</div>
                :
                <div className="prd">
                    <h1>
                        {prd.title}
                    </h1>
                    <h2>
                        Problem
                    </h2>
                    <Highlightable text={prd.problem} category={"problem"} prdId={prd.id} setPrd={setPrd} />
                    {/* <p> */}
                        {/* {prd.problem} */}
                        
                    {/* </p> */}
                    <h2>
                        Objective
                    </h2>
                    <Highlightable text={prd.objective} category={"objective"} prdId={prd.id} setPrd={setPrd} />
                    {/* <p>
                        {prd.objective}
                    </p> */}
                    <h2>
                        High Level Description
                    </h2>
                    <Highlightable text={prd.high_level_solution.hasOwnProperty("description") ? prd.high_level_solution.description : prd.high_level_solution} category={"high_level_solution"} prdId={prd.id} setPrd={setPrd} />

                    {/* <p>
                        {prd.high_level_solution.hasOwnProperty("description") ? prd.high_level_solution.description : prd.high_level_solution}
                    </p> */}

                    {
                        prd.high_level_solution.hasOwnProperty("user_flow") && <h3>User flow</h3>
                    }
                    {
                        prd.high_level_solution.hasOwnProperty("user_flow") && <ul>{listUserFlow}</ul>
                    }
                    {
                        prd.high_level_solution.hasOwnProperty("data_considerations") && <h3>Data considerations</h3>
                    }
                    {
                        prd.high_level_solution.hasOwnProperty("data_considerations") && <ul>{data_considerations}</ul>
                    }
                    <h2>
                        List of Features
                    </h2>
                        {listFeatures}
                    <h2>
                        Success metrics
                    </h2>
                        {listMetrics}
                    <h2>
                        Negative metrics
                    </h2>
                        {listNegativeMetrics}
                </div>
                
        }
    </div>
}