import React, {useState, useEffect, useRef} from 'react';
import {Button, Input, Header } from 'semantic-ui-react';
import './context.css';
import Toaster from './toaster';

export default function Context({setActiveItem}){
    const [personas, setPersonas] = useState("");
    const [product, setProduct] = useState("");
    const [business, setBusiness] = useState("");
    const [countPersonas, setCountPersonas] = useState(0);
    const [countBusiness, setCountBusiness] = useState(0);
    const [countProduct, setCountProduct] = useState(0);
    const [saving, setSaving] = useState(false);
    const isInitialMount = useRef(true);

    const MAX_CHARS = 200;

    useEffect(()=>{
        var _function = 'Context useEffect';
        console.log(_function, 'Fetching account data...');

        if(isInitialMount.current){
            isInitialMount.current = false;

            let token = localStorage.getItem('token');

            fetch('/api/users/v1/getContext', {
                method:'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    }
            })
            .then((response) => {
                if (!response.ok) {
                    console.log(_function,'error when calling to retrieve integrations information');
                    throw new Error('Network response was not ok');
                }
                else{
                    console.log(_function, 'Context found and processed');
                    return response.json();
                }
            })
            .then(data =>{
                console.log(data);
                setPersonas(data.result.personas);
                setProduct(data.result.product);
                setBusiness(data.result.business);
                setCountProduct(data.result.product.length);
                setCountPersonas(data.result.personas.length);
                setCountBusiness(data.result.business.length);
            })
            .catch(error => {
                console.log(_function,'error:',error);
            })
        }
    },[]);

    const handlePersonaChange = (e) => {
        var _function = 'handlePersonaChange';
        console.log(_function, 'called');

        if(e.target.value.length <= MAX_CHARS){
            setPersonas(e.target.value);
            setCountPersonas(e.target.value.length);
        }

    }

    const handleProductChange = (e) => {
        var _function = 'handleProductChange';
        console.log(_function, 'called');

        if(e.target.value.length <= MAX_CHARS){
            setProduct(e.target.value);
            setCountProduct(e.target.value.length);
        }
    }

    const handleBusinessChange = (e) => {
        var _function = 'handleBusinessChange';
        console.log(_function, 'called');

        if(e.target.value.length <= MAX_CHARS){
            setBusiness(e.target.value);
            setCountBusiness(e.target.value.length);
        }
    }
    
    /**
     * Handles when user click on login
     * @param {*} e 
     */
    const handleSave = (e) =>{
        var _function = 'handleSave';
        console.log(_function, 'called');

        let body = {
            product:product,
            business:business,
            personas:personas,
        }

        let token = localStorage.getItem('token');

        setSaving(true);

        fetch('/api/users/v1/updateContext', {
            method:'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
              },
            body:JSON.stringify(body)
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            console.log(_function, 'context successfully saved');
            setSaving(false);
        })
        .catch(error =>{
            console.log(_function, 'error while trying to save context');
        })
    }    


    return <div>
        <div className="contextCenter">
            <span onClick={()=>setActiveItem('initiatives')} style={{ cursor: 'pointer', color: '#618FA8' }}>Back home</span>
            <div className="contextInputSection">
                <div className="contextHeader">
                    <Header as='h2' style={{color:'#1f496f'}} >Business Context</Header>
                </div>
                <div className="contextNote">
                    Context is a way for users to increase the quality of the PRD output by providing additional context on the business such as personas, value proposition and the product.
                </div>

                <div className="contextSubHeader">
                    <Header as='h3' style={{color:'#1f496f'}}>Business</Header>
                </div>
                <div className="contextInstructions">
                    Describe the value proposition of your business and its economic drivers
                </div>
                <div className='initInput'>
                    <Input fluid placeholder="Describe the value proposition of your business and its economic drivers" value={business} onChange={handleBusinessChange} input={{ style: { borderRadius: '10px' } }}  />
                    <div className='initCounter'>
                        {countBusiness}/{MAX_CHARS}
                    </div>
                </div>

                <div className="contextSubHeader">
                    <Header as='h3' style={{color:'#1f496f'}}>Personas</Header>
                </div>                
                <div className="contextInstructions">
                    Describe the users of your products, their roles and their objectives
                </div>
                <div className='initInput'>
                    <Input fluid placeholder="Describe the users of your products, their roles and their objectives" value={personas} onChange={handlePersonaChange} input={{ style: { borderRadius: '10px' } }} />
                    <div className='initCounter'>
                        {countPersonas}/{MAX_CHARS}
                    </div>                        
                </div>

                <div className="contextSubHeader">
                    <Header as='h3' style={{color:'#1f496f'}}>Product</Header>
                </div>                
                <div className="contextInstructions">
                    Describe your product and its key capabilities
                </div>
                <div className='contextInput'>
                    <Input fluid placeholder="Describe your product and its key capabilities" value={product} onChange={handleProductChange} input={{ style: { borderRadius: '10px' } }} />
                    <div className='initCounter'>
                        {countProduct}/{MAX_CHARS}
                    </div>                        
                </div>
            </div>

            <div className="contextButton">
                <Button primary onClick={handleSave} loading={saving}>Save</Button>
            </div>
            <Toaster />
        </div>
    </div>
}