import React, {useState, useEffect, useRef} from 'react';
import {Button, Input, Table,  TableRow, TableHeaderCell, TableHeader, TableCell, TableBody, TableFooter, Icon } from 'semantic-ui-react';
import { ACCOUNT_TYPES, REVERSE_ACCOUNT_TYPES, isAfterToday } from '../utils';
import './accounts.css';

export default function Accounts({setActiveItem, accountType, setType}){
    const [email, setEmail] = useState("");
    const [accountId, setAccountId] = useState("");
    const [subscribing, setSubscribing] = useState(false);
    const [isCanceled, setCanceled] = useState(false);
    const [endDate, setEndDate] = useState("");
    const isInitialMount = useRef(true);
    const wsRef = useRef(null);


    useEffect(()=>{
        var _function = 'Accounts useEffect';
        let token = localStorage.getItem('token');

        if(isInitialMount.current && token){
            console.log(_function, 'Fetching account data...');
            isInitialMount.current = false;

            console.log(_function, 'Initial mount');

            fetch('/api/users/v1/getInfo', {
                method:'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    }
            })
            .then((response) => {
                if (!response.ok) {
                    console.log(_function,'error when calling to retrieve integrations information');
                    throw new Error('Network response was not ok');
                }
                else{
                    console.log(_function, 'integrations found and processed');
                    return response.json();
                }
            })
            .then(data =>{
                console.log(data);
                setEmail(data.result.email);
                setAccountId(data.result.id);

                // is there a subscription
                if(data.result.stripe_sub_end){

                    // Keep the YYYY-MM-DD for display purposes
                    let _end = data.result.stripe_sub_end.split('T')[0];
                    setEndDate(_end);
                    if(data.result.stripe_canceled_at){
                        setCanceled(true);
                    }
                }
            })
            .catch(error => {
                console.log(_function,'error:',error);
            })
        }

        if(!wsRef.current && accountId !== ""){
            console.log(_function, 'setting up WebSocket');
            // eventSourceRef.current = new EventSource(process.env.REACT_APP_MONITOR_URL+'events/'+accountId);

            // wsRef.current = new WebSocket(`/ws?accountId=${accountId}`); // Use ws:// or wss:// for secure connections
            wsRef.current = new WebSocket(`/ws?accountId=${accountId}`); // Use ws:// or wss:// for secure connections

            wsRef.current.onopen = () => {
                console.log('Connected to WebSocket server');
            };
    
            wsRef.current.onmessage = (event) => {
                const message = JSON.parse(event.data);
                console.log('Message received:', message);
                if(message.account_id == accountId){
                    console.log(_function, 'received message of subscription');
                    setSubscribing(false);
                    setType(ACCOUNT_TYPES.paid);
                }
            };
    
            wsRef.current.onerror = (error) => {
                console.error('WebSocket error:', error);
            };
    
            wsRef.current.onclose = () => {
                console.log('WebSocket connection closed');
            };
        }
    }, [accountId]);


    useEffect(() => {
        return () => {
            console.log('Accounts useEffect Empty', 'Component unmounting');
            if(wsRef.current){
                wsRef.current.close();
            }
        }
    }, [])

    /**
     * Method used on the account settings to update the email of the user
     * @param {*} e 
     */
    const handleUpdateEmail = (e) => {
        var _function = 'handleUpdateEmail';
        console.log(_function, 'called');

        let body = {
            email:email,
        }

        let token = localStorage.getItem('token');

        fetch('/api/users/v1/changeemail', {
            method:'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
              },
            body:JSON.stringify(body)
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .catch(error =>{
            console.log(_function, 'error while trying to update email');
        })
    }

    /**
     * This function handles when users click on upgrade account
     */
    const handleSubscribePremium = () =>{
        var _function = 'handleSubscribePremium';
        console.log(_function, 'called');

        let token = localStorage.getItem('token');

        fetch('/api/users/v1/getStripePaymentLink', {
            method:'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
                }
        })
        .then((response) =>{
            if(!response.ok){
                console.log(_function, 'error while getting the payment URL');
                throw new Error('Network response was not ok');
            }
            else{
                return response.json();
            }
        })
        .then((data) =>{
            console.log(_function, 'received payment url, opening now: ', data);
            window.open(data.url);
            setSubscribing(true);
        })
        .catch((error) => {
            console.log(_function, 'error:', error);
        });
    }

    /**
     * Handles when user is typing and updating the email
     * @param {*} e 
     */
    const handleEmailChange = (e) => {
        var _function = 'handleEmailChange';
        console.log(_function, 'called');

        setEmail(e.target.value);
    }

    /**
     * Methid used on the account settings to cancel the account
     * @param {*} e 
     */
    const handleDeleteAccount = (e) => {

    }    

    return <div className='accountCenter'>
        <span onClick={()=>setActiveItem('initiatives')} style={{ cursor: 'pointer', color: '#618FA8' }}>Back home</span>
        <div className ='accountSection'>
            <div className='accountHeader'>
                <h2>Your account</h2>
            </div>
            <div className="accountSubheader">To update your login information or cancel your account, use the following options: </div>
            <div className='accountInput'>
                <Input label='Your email' placeholder='my@email.com' value={email} />
            </div>
            <div className='accountButtons'>
                <Button primary onClick={handleUpdateEmail} onChange={handleEmailChange} className='accountSingleButton'>Save</Button>
                <Button color='red' onClick={handleDeleteAccount} className='accountSingleButton'>Delete</Button>
            </div>
            <div className="accountSubheader">To give us feedback or contact us regarding account issues, please use the following email: <b>support@productly.io</b></div>
        </div>
        <hr/>
        <div className="accountBilling">
            <h2>Your subscription</h2>
            <div className="accountSubNotice">
                {
                    endDate !== "" && isAfterToday(endDate) && 
                        (isCanceled ? 
                            <>Your subscription will cancel on <b>{endDate}</b></>
                        :
                            <>Your subscription will renew on <b>{endDate}</b></>)
                }
            </div>
            <div className="accountSubheader">To upgrade your subscription choose one of the following options</div>
            <div className="accountTableSub">
                <Table celled padded striped >
                    <TableHeader>
                        <TableRow>
                            <TableHeaderCell></TableHeaderCell>
                            <TableHeaderCell textAlign='center'>Starter</TableHeaderCell>
                            <TableHeaderCell textAlign='center'>Premium</TableHeaderCell>
                            <TableHeaderCell textAlign='center'>Enterprise</TableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        <TableRow>
                            <TableCell>Price</TableCell>
                            <TableCell textAlign='center'>Free</TableCell>
                            <TableCell textAlign='center'>$10 / month</TableCell>
                            <TableCell textAlign='center'>Contact us</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Users</TableCell>
                            <TableCell textAlign='center'>1</TableCell>
                            <TableCell textAlign='center'>1</TableCell>
                            <TableCell textAlign='center'>Unlimited</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell># PRDs & Stories generation per month</TableCell>
                            <TableCell textAlign='center'>5</TableCell>
                            <TableCell textAlign='center'>Unlimited</TableCell>
                            <TableCell textAlign='center'>Unlimited</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Product context</TableCell>
                            <TableCell textAlign='center'><Icon color='green' name='check circle' /></TableCell>
                            <TableCell textAlign='center'><Icon color='green' name='check circle' /></TableCell>
                            <TableCell textAlign='center'><Icon color='green' name='check circle' /></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Integration Jira, Confluence, Notion</TableCell>
                            <TableCell textAlign='center'></TableCell>
                            <TableCell textAlign='center'><Icon color='green' name='check circle' /></TableCell>
                            <TableCell textAlign='center'><Icon color='green' name='check circle' /></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Multiple contexts</TableCell>
                            <TableCell textAlign='center'></TableCell>
                            <TableCell textAlign='center'></TableCell>
                            <TableCell textAlign='center'><Icon color='green' name='check circle' /></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Fine-tuned style</TableCell>
                            <TableCell></TableCell>
                            <TableCell textAlign='center'><Icon color='green' name='check circle' /><br/><span style={{fontSize:'smaller'}}>coming soon</span></TableCell>
                            <TableCell textAlign='center'><Icon color='green' name='check circle' /><br/><span style={{fontSize:'smaller'}}>coming soon</span></TableCell>
                        </TableRow>
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableHeaderCell/>
                            <TableHeaderCell textAlign='center'>{accountType === ACCOUNT_TYPES.free && <Icon color='green' name='check' size='large' />}</TableHeaderCell>
                            <TableHeaderCell textAlign='center'>{accountType === ACCOUNT_TYPES.free ? <Button primary onClick={handleSubscribePremium} loading={subscribing}>Upgrade</Button> : <Icon color='green' name='check' size='large' />}</TableHeaderCell>
                            <TableHeaderCell textAlign='center'><Button primary disabled>Coming soon</Button></TableHeaderCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </div>
            <div className="accountManageSub">
                To manage your billing information and your subscription, head to the <a href={process.env.REACT_APP_STRIPE_PORTAL} target='_blank' rel='noreferrer'>customer portal</a>.
            </div>
        </div>
    </div>
}