import React, {useState} from 'react';
import { Modal, ModalHeader, ModalContent, ModalActions, Button } from 'semantic-ui-react';
import './termsconditions.css';

export default function TermsConditions({open, setOpen}){
    return <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
    >
        <ModalHeader>Productly Terms & Conditions</ModalHeader>
        <ModalContent>
            <div>
            <b>Effective Date:</b> August, 1st, 2024<br/><br/>

            Welcome to Productly! These Terms and Conditions ("Terms") govern your use of the Productly platform ("Platform"), a SaaS solution designed to assist product managers with various tasks including generating PRDs, user stories, and integrating with third-party tools.<br/><br/>

            By accessing or using the Platform, you agree to be bound by these Terms. If you do not agree to these Terms, you may not use the Platform.

            <h1>1. Definitions</h1>
            <ul>
            <li>"Platform" refers to the Productly SaaS platform, including all services, features, and functionalities provided by Productly.</li>
            <li>"User" refers to any individual or entity accessing or using the Platform.</li>
            <li>"Account" refers to the User’s registered account on the Platform.</li>
            <li>"Content" refers to any data, text, images, software, or other material created, uploaded, or transmitted through the Platform.</li>
            </ul>
            <h1>2. Eligibility</h1>
            To use the Platform, you must:
            <ul>
            <li>Be at least 18 years old.</li>
            <li>Have the legal capacity to enter into binding agreements.</li>
            <li>Agree to comply with all applicable laws and regulations.</li>
            </ul>
            <h1>3. Account Registration</h1>
            <ul>
            <li>Users must create an Account to access the Platform.</li>
            <li>All information provided during registration must be accurate and up-to-date.</li>
            <li>Users are responsible for maintaining the confidentiality of their Account credentials and are liable for all activities conducted under their Account.</li>
            </ul>
            <h1>4. Subscription and Payment</h1>
            <ul>
            <li>Access to the Platform is subject to subscription fees, as described on the Productly website.</li>
            <li>Subscription fees are non-refundable except as provided in these Terms or as required by applicable law.</li>
            <li>Productly reserves the right to modify subscription fees at any time. Changes will take effect at the start of the next billing cycle.</li>
            </ul>
            <h1>5. Use of the Platform</h1>
            <ul>
            <li>Users may use the Platform only for lawful purposes and in accordance with these Terms.</li>
            <li>Users agree not to:</li>
            <ul>
            <li>Use the Platform in any way that violates any applicable federal, state, local, or international law or regulation.</li>
            <li>Engage in any activity that could harm or disrupt the Platform or its servers.</li>
            <li>Attempt to gain unauthorized access to any part of the Platform.</li>
            <li>Upload or transmit any viruses or other harmful code.</li>
            </ul>
            </ul>
            <h1>6. Intellectual Property</h1>
            <ul>
            <li>Productly retains all rights, title, and interest in and to the Platform, including all related intellectual property.</li>
            <li>Users are granted a limited, non-exclusive, non-transferable license to access and use the Platform in accordance with these Terms.</li>
            <li>Users may not copy, modify, distribute, sell, or lease any part of the Platform without Productly’s prior written consent.</li>
            </ul>
            <h1>7. User Content</h1>
            <ul>
            <li>Users retain ownership of any Content they create, upload, or transmit through the Platform.</li>
            <li>By uploading Content, Users grant Productly a non-exclusive, royalty-free, worldwide license to use, store, and display the Content for the purpose of operating the Platform.</li>
            <li>Users are solely responsible for the Content they upload and must ensure that it does not infringe any third-party rights.</li>
            </ul>
            <h1>8. Third-Party Integrations</h1>
            <ul>
            <li>The Platform may offer integrations with third-party tools (e.g., Confluence, Notion, Jira).</li>
            <li>Users acknowledge that Productly is not responsible for the functionality or availability of these third-party tools.</li>
            <li>Use of third-party tools is subject to the terms and conditions of those third parties.</li>
            </ul>
            <h1>9. Termination</h1>
            <ul>
            <li>Productly reserves the right to suspend or terminate any Account that violates these Terms or engages in unauthorized activities.</li>
            <li>Users may terminate their subscription at any time, with termination taking effect at the end of the current billing cycle.</li>
            <li>Upon termination, Users will lose access to the Platform and all associated data.</li>
            <li>Users have the option to downgrade their subscription at any time to the Starter plan, with the downgrade taking effect at the end of the current billing cycle.</li>
            </ul>
            <h1>10. Limitation of Liability</h1>
            <ul>
            <li>Productly is provided "as is" and "as available," without any warranties, express or implied.</li>
            <li>Productly shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising from the use or inability to use the Platform.</li>
            <li>In no event shall Productly’s total liability exceed the amount paid by the User to Productly in the twelve (12) months preceding the claim.</li>
            </ul>
            <h1>11. Indemnification</h1>
            Users agree to indemnify and hold harmless Productly, its affiliates, and its employees from any claims, damages, losses, or expenses arising from the User’s use of the Platform or violation of these Terms.
            <h1>12. Governing Law and Jurisdiction</h1>
            These Terms shall be governed by and construed in accordance with the laws of United States of America and the State of California.
            Any legal action arising out of or related to these Terms or the Platform shall be brought exclusively in the courts of California.
            <h1>13. Changes to Terms</h1>
            Productly reserves the right to modify these Terms at any time.
            Changes will be effective immediately upon posting on the Platform.
            Continued use of the Platform after any changes constitutes acceptance of the new Terms.
            <h1>14. Contact Information</h1>
            For any questions or concerns regarding these Terms, please contact us at support@productly.io<br/><br/>

            By using the Platform, you acknowledge that you have read, understood, and agree to be bound by these Terms.
            </div>
        </ModalContent>
        <ModalActions>
            <Button onClick={ ()=>setOpen(false)}>Close</Button>
        </ModalActions>
    </Modal>
}