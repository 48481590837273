import React, {useState} from 'react';
import { Modal, ModalHeader, ModalContent, ModalActions, Button } from 'semantic-ui-react';
import './notionhowto.css';

export default function NotionHowTo({open, setOpen}){
    return <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
    >
        <ModalHeader>Instructions to connect Notion</ModalHeader>
        <ModalContent>
            <h3>1. Create an integration</h3>
                <ul>
                    <li>Go to the <a href='https://www.notion.so/my-integrations' rel='noreferrer' target='_blank'>Notion Integrations page</a>.</li>
                    <li>Click "New integration".</li>
                    <li>Give your integration a name, select the workspace it will operate in, and save the integration.</li>
                    <li>Copy the integration token and store it securely.</li>
                    <li>Input the integration token in the integration page.</li>
                </ul>

            <h3>2. Share a page with the integration</h3>
                <ul>
                    <li>Go to the page(s) you will upload PRDs to</li>
                    <li>Click on the "..." in the top right corner</li>
                    <li>Scroll to the bottom of the menu to "Connect to"</li>
                    <li>Pick the name entered in the previous step and confirm access.</li>
                </ul>
        </ModalContent>
        <ModalActions>
            <Button onClick={ ()=>setOpen(false)}>Close</Button>
        </ModalActions>
    </Modal>
}