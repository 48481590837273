import React, {useState} from 'react';
import {Button, Input, Header, Segment } from 'semantic-ui-react';
import { isValidEmail } from '../utils';
import TermsConditions from './termsconditions';
import './signup.css';

export default function Signup({switchToLogin, storeToken, setLimit, setType, setAccountId}){
    const [email, setEmail] = useState("");
    const [open, setOpen] = useState(false);
    const [code, setCode] = useState("");
    const [codeSent, setCodeSent] = useState(false);
    const [incorrect, setIncorrect] = useState(false);
    const [loading, setLoading] = useState(false);


    /**
     * Handles input for Email change
     * @param {*} e 
     */
    const handleEmailChange = (e) => {
        var _function = 'handleEmailChange';
        console.log(_function, 'called');

        setEmail(e.target.value);
    }

    /**
     * Handles input for Code
     * @param {*} e 
     */
    const handleCodeChange = (e) => {
        var _function = 'handleCodeChange';
        console.log(_function, 'called');

        setCode(e.target.value);
    }

    /**
     * Check if the user press ENTER to actionate the button
     * @param {*} e 
     */
    const handleEmailKeyPress = (e) => {
        var _function = 'handleEmailKeyPress';
        //console.log(_function, ' called');

        if(e.key === 'Enter'){
            handleSignup();
        }
    }

    /**
     * Check if the user press ENTER to actionate the button
     * @param {*} e 
     */
    const handleCodeKeyPress = (e) => {
        var _function = 'handleCodeKeyPress';
        //console.log(_function, ' called');

        if(e.key === 'Enter'){
            handleVerifCode();
        }
    }    

    /**
     * Function that handles when user sign-up, i.e. provides their email.
     * @param {*} e 
     */
    const handleSignup = (e) =>{
        var _function = 'handleSignup';
        console.log(_function, 'called');

        if(email === '' || !isValidEmail(email)){
            // TO DO -- Add error message when the email is left empty
            console.log(_function, 'no email or invalid email');
            return ;
        }
        else{
            setLoading(true);

            let body = {
                email:email,
            }
    
            fetch('/api/users/v1/signup', {
                method:'POST',
                headers: {
                    "Content-Type": "application/json",
                    },
                body:JSON.stringify(body)
            })
            .then((response) => {
                setLoading(false);

                if (!response.ok) {
                    setIncorrect(true);
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                console.log(_function, 'code has been sent', data.message);
                setCodeSent(true);
            })
            .catch(error =>{
                console.log(_function, 'error while trying to signing up email');
            })
        }
    }

    /**
     * Function that handles when user clicks on "Verif Code".
     * @param {*} e 
     */
    const handleVerifCode = (e) => {
        var _function = 'handleVerifCode';
        console.log(_function, 'called');

        if(code === ''){
            console.log(_function, 'code is empty');
        }
        else{
            setLoading(true);

            let body = {
                code:code,
                email:email,
            }
    
            // TODO - Send code to backend to check if the code is the right one.
            fetch('/api/users/v1/validatecode', {
                method:'POST',
                headers: {
                    "Content-Type": "application/json",
                  },
                body:JSON.stringify(body)
            })
            .then((response) => {
                setLoading(false);

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                console.log(_function, 'code has been validated');
                setLimit(data.account_limit);
                setType(data.account_type);
                storeToken(data.token, true);
                setAccountId(data.account_id);
            })
            .catch(error =>{
                console.log(_function, 'error while trying to signing up email');
            })
        }

    }

    return <div className="signupMain">
        {
            codeSent ?
            <div className="signupCenter">
                <div className="signupHeader">
                    <Header as='h2' style={{color:'#1f496f'}}>Enter the code sent to the email provided</Header>
                </div>
                <div className="signupInput">
                    <Input placeholder="12345" value={code} onChange={handleCodeChange} onKeyPress={handleCodeKeyPress} />
                    {
                        incorrect &&
                            <div className='loginError'>
                                Code is incorrect or has exceeded its time limit.
                            </div>
                    }                    
                </div>
                <div className="signupButton">
                    <Button primary loading={loading} onClick={handleVerifCode}>Verify code</Button>
                </div>
            </div>
            :
            <div className="signupCenter">
                <div className="signupHeader">
                    <Header as='h2' style={{color:'#1f496f'}}>To sign-up, please enter your email</Header>
                </div>
                <div className="signupInput">
                    <Input placeholder="john.doe@email.com" fluid value={email} onChange={handleEmailChange} onKeyPress={handleEmailKeyPress} />
                </div>
                <div className="signupButton">
                    <Button primary loading={loading} onClick={handleSignup}>Sign up</Button>
                </div>
                <div className="signupNote">
                    If you already have an account, <span onClick={switchToLogin} style={{ cursor: 'pointer', color: '#618FA8' }}>login here</span>.
                </div>
                <div className="signupTC">
                    By signing-up, you agree to the <span onClick={(e) => setOpen(true)} style={{ cursor: 'pointer', color: '#618FA8' }}>Terms & Conditions</span> of this website.
                </div>
                <TermsConditions open={open} setOpen={setOpen} />
            </div>
        }
    </div>
}