import React, {useState, useEffect, useRef} from 'react';
import { Button, Dropdown, DropdownItem, DropdownMenu, Message, Placeholder, PlaceholderLine, Popup } from 'semantic-ui-react';
import UserStory from './userstory';
import './tabstories.css';

export default function TabStories({prd, initiative_id, userStories, setUserStories, setActiveItem, promptHasChanged}){
    const isInitialMount = useRef(true);
    const [listProjects, setListProjects] = useState([]);
    const [generating, setGenerating] = useState(false);
    

    useEffect(()=>{
        var _function = 'TabStories useEffect';
        console.log(_function, 'called');

        let token = localStorage.getItem('token');

        if(isInitialMount.current && token){
            isInitialMount.current = false;

            fetch('/api/integrations/v1/jiraprojects', {
                method:'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                  }
            })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                console.log(_function, 'successfully published all JIRA stories');
                setListProjects(data.projects);
            })
            .catch((error) => {
                console.error('There was a problem with sending JIRA story:', error);
            });

        }
    }, []);

   /**
     * Method called when 'Generate stories' is pressed.
     * @param {*} e 
     */
    const handleGenerateStories = (e) => {
        var _function = 'handleGenerateStories';
        console.log(_function,"called");

        let body = {
            prd:prd,
            initiative_id:initiative_id,
        }

        let token = localStorage.getItem('token');

        setGenerating(true);

        fetch('api/initiatives/v1/createStories', {
            method:'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body:JSON.stringify(body)
        })
        .then(result => {
            setGenerating(false);

            if(!result.ok){
                throw new Error("Error while generating stories");
            }

            return result.json();
        
        })
        .then((response) => {
            setUserStories(response.stories)
            console.log(response.stories);
            return response.stories;
        })
        .catch(error =>{
            console.log(_function, error);
        })
    }

    /**
     * Method called when the button 'send all to Jira' is pressed.
     */
    const handleSendAlltoJira = (_projectKey) =>{
        var _function = 'handleSendAlltoJira';

        let story_ids = userStories.map((o) => {return o.id});

        let body ={
            stories:story_ids,
            projectKey:_projectKey,
        }

        let token = localStorage.getItem('token');

        fetch('/api/stories/v1/bulksendtojira', {
            method:'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
              },
            body:JSON.stringify(body)
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then((data) => {
            console.log(_function, 'successfully published all JIRA stories');
            const updatedStories = userStories.map(story => {
                 return { ...story, published: true }}
            );

            console.log(updatedStories);
    
            // Update the state with the new array
            setUserStories(updatedStories);
        })
        .catch((error) => {
            console.error('There was a problem with sending JIRA story:', error);
        });
    }

    /**
     * The id of the story whose 'published' state needs to be set to true in the State.
     * This function is a callback function called by the userStory component.
     * @param {string} idStory 
     */
    const updateStoryStatus = (idStory) => {
        const updatedStories = userStories.map(story =>
            story.id === idStory ? { ...story, published: true } : story
        );

        // Update the state with the new array
        setUserStories(updatedStories);
    }


    const dropdownJira = listProjects.map(p => {
        return <DropdownItem value={p.id} onClick={(e) => handleSendAlltoJira(p.key)}>Send all to <b>{p.name +' ('+p.key+')'}</b></DropdownItem>
    });


    // Create list of user stories for render.
    const listStories = userStories.map((o,i) => {
        return <UserStory story={o} callbackUpdateStoryStatus={updateStoryStatus} key={'story_'+i.toString()} listProjects={listProjects} setActiveItem={setActiveItem}/>
    });    

    return  <div className="panelStoriesContent">
        <div id="intro-publish-stories" className="panelStoriesActions">
            <Button disabled={!prd.hasOwnProperty('id')} loading={generating} primary onClick={handleGenerateStories}>{userStories.length === 0 ? 'Generate Stories \u{2728}' : promptHasChanged ? 'Re-Generate Stories' : 'Generate Stories'}</Button>
            <Popup trigger={
                <div>
                    <Dropdown disabled={userStories.length === 0 || dropdownJira.length === 0} className="panelStoriesDropdownImage"
                        trigger={<img src="/images/mark-gradient-blue-jira.png" alt="Logo" style={{ height: '25px', width: '25px', objectFit: 'cover' }} />}
                    >
                        <DropdownMenu>
                            {dropdownJira}
                        </DropdownMenu>
                    </Dropdown>
                </div>
            } flowing hoverable disabled={!prd.hasOwnProperty('id') || dropdownJira.length>0}>
            Connect to <span onClick={()=>setActiveItem('integrations')} style={{ cursor: 'pointer', color: '#618FA8' }} >Jira</span>
        </Popup>
        </div>
        <div className="panelStoriesDisplay">
            {
                generating ?
                <Placeholder fluid>
                    <PlaceholderLine/>
                    <PlaceholderLine/>
                    <PlaceholderLine/>
                    <PlaceholderLine/>
                    <PlaceholderLine/>
                    <PlaceholderLine/>
                </Placeholder>
                :
                userStories.length === 0 ?
                    <div className="panelStoriesEmpty">
                        <Message info float compact>{initiative_id ? "Click Generate Stories" : "Generate a PRD first."}</Message>
                    </div>
                    :
                    listStories
            }
        </div>
    </div>
}