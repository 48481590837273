import React, {useState, useEffect, useRef} from 'react';
import { Button, Popup, Dropdown, DropdownMenu, DropdownItem, Message, Placeholder, PlaceholderLine, Icon } from 'semantic-ui-react';
import Prd from './prd';

import './tabprd.css';

export default function TabPrd({initiative, setInitiativeId, prd, setPrd, setActiveItem, setHistoryChange, setPromptHasChanged, promptHasChanged, checkCanGenerate, includeContext }){
    const [pages, setPages] = useState([]);
    const [notionPages, setNotionPages] = useState([]);
    const isInitialMount = useRef(true);
    const [generating, setGenerating] = useState(false);
    const [loadingConfluence, setLoadingConfluence] = useState(false);
    const [loadingNotion, setLoadingNotion] = useState(false);
    const [hasCopied, setHasCopied] = useState(false);
    const contentRef = useRef();


    useEffect(()=>{
        var _function = ' TabPrd useEffect';
        console.log(_function, 'called');
        console.log(_function, 'logged in, loading confluence spaces');

        let token = localStorage.getItem('token');

        if(isInitialMount.current && token){
            isInitialMount.current = false;

            console.log(_function, 'getting confluence spaces');
            // This fetch is about finding confluence spaces
            fetch('/api/integrations/v1/confluencespaces', {
                method:'GET',
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`
                },
            })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                return response.json();
            })
            .then((result) =>{
                console.log(_function, 'processing json for confluence pages');
                console.log(result);
                setPages(result.pages);
            })
            .catch(e =>{
                console.log(_function, 'error loading confluence pages');
            });

            console.log(_function, 'getting notion pages');
            // This fetch is about finding notion pages that are connected to the integration

            fetch('/api/integrations/v1/notionpages', {
                method:'GET',
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`
                },
            })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok while fetching notionpages');
                }

                return response.json();

            })
            .then((result) =>{
                console.log(_function, 'processing json for notion pages');
                console.log(result);
                setNotionPages(result.pages);
            })
            .catch(error => {
                console.log(_function, 'error while fetching data:',error);
            })

        }
    });

    /**
     * Method called when 'Generate PRD' is pressed.
     * @param {*} e 
     */
    const handleGeneratePrd = (e) => {
        var _function = 'handleGeneratePrd';
        console.log(_function, 'called');

        if(initiative !== ''){
            // Handle the generate button click
            console.log('Initiative:', initiative);

            setGenerating(true);
            
            let body = {
                initiative:initiative,
                context:includeContext
            }
    
            let token = localStorage.getItem('token');
    
            // PROCESSING FOR THE PRD
            fetch('api/initiatives/v1/createPrd', {
                method:'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                  },
                body:JSON.stringify(body)
            })
            .then(result => {
                setGenerating(false);
                setHistoryChange(true);
                setPromptHasChanged(false);

                if(parseInt(result.status) === 200){
                    console.log(_function, 'successfull call to get PRD');
                    return result.json();
                }
                else{
                    console.log(result);
                    return {prd:"Error processing PRD"};
                }
            })        
            .then((response) => {
                console.log(_function, 'successfully processed the PRD response and saving it in the state');
                console.log(response);
                setInitiativeId(response.initiative_id);
                setPrd(response.prd);
            })
            .catch((error) => {
                console.log(_function, 'error while generating PRD');
            });
        }

    };
    
    /**
     * Called when the user changes the value in the Dropdown "Publish to Confluence"
     * @param {string} spaceId
     */
    const handlePublishToConfluence = (spaceId) =>{
        var _function = 'handlePublishToConfluence';
        console.log(_function, 'called for spaceId: ', spaceId);


        if(prd.hasOwnProperty('id')){
            const body = {
                id:prd.id,
                spaceid:spaceId,
            };

            setLoadingConfluence(true);
    
            let token = localStorage.getItem('token');
    
            fetch(`api/prds/v1/sendtoconfluence`, {
                method:'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                  },
                body:JSON.stringify(body)
            })
            .then(result => {
                setLoadingConfluence(false);

                if(parseInt(result.status) === 200){
                    return result.json();
                }
                else{
                    return {prd:"Error publishing PRD"};
                }
            })        
            .then((response) => {
                console.log(_function, 'successfully published the PRD ');
            }) 
        }
    }

    /**
     * Copies the text of the PRD to the clipboard
     */
    const copytoClipboard = () =>{
        // navigator.clipboard.writeText("test");
        const contentHtml = contentRef.current.innerHTML;
        const blob = new Blob([contentHtml], { type: 'text/html' });
        const clipboardItem = new ClipboardItem({ 'text/html': blob });

        navigator.clipboard.write([clipboardItem])
        .then(()=>{
            setHasCopied(true);
            setTimeout(()=>{setHasCopied(false)}, 2000);
        })
        .catch((err) =>{
            console.error('Browser incompatible with copy to clipboard. ', err);
        })

    }

    /**
     * Handles the action of publishing to Notion
     * @param {*} pageid 
     */
    const  handlePublishToNotion = (pageid) => {
        var _function = 'handlePublishToNotion';
        console.log(_function, 'called for pageId: ', pageid);

        
        if(prd.hasOwnProperty('id')){
            const body = {
                id:prd.id,
                pageid:pageid,
            };

            setLoadingNotion(true);
            
            let token = localStorage.getItem('token');
    
            fetch(`api/prds/v1/sendtonotion`, {
                method:'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                  },
                body:JSON.stringify(body)
            })
            .then(result => {
                setLoadingNotion(false);

                if(parseInt(result.status) === 200){
                    return result.json();
                }
                else{
                    return {prd:"Error publishing PRD"};
                }
            })        
            .then((response) => {
                console.log(_function, 'successfully published the PRD ');
            }) 
        }
    }

    // const spaceOptions = pages.map((p) => {return {key:p.key, text:p.name, value:p.id}});
    const spaceOptions = pages.map((p) => {
        return <DropdownItem key={"dd_item_confluence_"+p.id} value={p.id} onClick={(e) => handlePublishToConfluence(p.id)}>Publish to <b>{p.name}</b></DropdownItem>
    });

    const notionOptions = notionPages.map(p => {
        return <DropdownItem key={"dd_item_notion_"+p.id} value={p.pageId} onClick={(e) => handlePublishToNotion(p.pageId)}>Publish to <b>{p.pageTitle}</b></DropdownItem>
    });


    return <div className="panelPrdContent">
    <div className="panelPrdActions">
        <div className="panelPrdQuickActions">

        </div>
        <Popup trigger={
            <div>
                <Button id="intro-click-generate" primary disabled={initiative === '' || !checkCanGenerate()} loading={generating} onClick={handleGeneratePrd}>Generate PRD <span className="large-emoji">{`\u{2728}`}</span></Button>
            </div>
         }flowing hoverable disabled={checkCanGenerate()}>
            Monthly quota reached.<br/>
            Upgrade your  <span onClick={()=>setActiveItem('account')} style={{ cursor: 'pointer', color: '#618FA8' }} >plan</span>
         </Popup>
        
        <Popup trigger={
            <div id="intro-click-publish">
                <Dropdown key="dd_confluence" disabled={!prd.hasOwnProperty('id') || spaceOptions.length === 0} loading={loadingConfluence} className="panelDropdownImage"
                    trigger={<img src="/images/mark-gradient-blue-confluence.png" alt="Logo" style={{ height: '25px', width: '25px', objectFit: 'cover' }} />}
                >
                    <DropdownMenu>
                        {spaceOptions}
                    </DropdownMenu>
                </Dropdown>
            </div>
        } flowing hoverable disabled={!prd.hasOwnProperty('id') || spaceOptions.length>0}>
            Connect to <span onClick={()=>setActiveItem('integrations')} style={{ cursor: 'pointer', color: '#618FA8' }} >Confluence</span>
        </Popup>

        <Popup trigger={
            <div>
                <Dropdown key="dd_notion" disabled={!prd.hasOwnProperty('id') || notionOptions.length === 0} loading={loadingNotion} className="panelDropdownImage"
                    trigger={<img src="/images/notion-logo.png" alt="Logo" style={{ height: '25px', width: '25px', objectFit: 'cover' }} />}
                >
                    <DropdownMenu>
                        {notionOptions}
                    </DropdownMenu>
                </Dropdown>
            </div>
        } flowing hoverable disabled={!prd.hasOwnProperty('id') || notionOptions.length>0}>
            Connect to <span onClick={()=>setActiveItem('integrations')} style={{ cursor: 'pointer', color: '#618FA8' }} >Notion</span>
        </Popup>

        <div className="panelPrdQuickActions">
            <Popup trigger={
                <div>
                    <Icon name={hasCopied ? 'clipboard check' :'clipboard outline'} disabled={!prd.hasOwnProperty('id')} onClick={copytoClipboard} link />
                </div>
            } flowing hoverable position='top left'>
                Copy to clipboard
            </Popup>
        </div>
        
    </div>

    <div className="panelPrdContentDisplay">
        {
            prd.hasOwnProperty('id') && !generating ?
                <div ref={contentRef}>
                    <Prd prd={prd} setPrd={setPrd}></Prd>
                </div>
            :
                generating ?
                    <Placeholder fluid>
                        <PlaceholderLine />
                        <PlaceholderLine />
                        <PlaceholderLine />
                        <PlaceholderLine />
                        <PlaceholderLine />
                        <PlaceholderLine />
                    </Placeholder>
                    :
                    <div className="panelPrdEmpty">
                        <Message info compact>Describe an initiative and click Generate PRD.</Message>
                    </div>
        }
    </div>
</div>
}