import React, { useState, useEffect } from 'react';
import './toaster.css';

const Toaster = ({ message, onClose }) => {
    useEffect(() => {
        if (message) {
            const timer = setTimeout(() => {
                onClose();
            }, 3000); // Hide the toaster after 3 seconds

            return () => clearTimeout(timer);
        }
    }, [message, onClose]);

    if (!message) return null;

    return (
        <div className='toaster'>
            {message}
        </div>
    );
};


export default Toaster;
